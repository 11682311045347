import { inject } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../common/auth';
import { IRouteProvider } from '../modules/commons/layouts/common/models/layout-config.model';
import { cloneDeepRoutes, RouteInfo } from '../modules/commons/layouts/common/models/route-info.model';

export const ROUTES: RouteInfo[] = [
  {
    title: 'APPLICATION.MENU.NAV.business',
    children: [
      {
        title: 'APPLICATION.MENU.NAV.organization',
        icon: 'ph-duotone ph-building-office',
        path: '/user-profile/organization',
      },
      {
        title: 'APPLICATION.MENU.NAV.organizations',
        icon: 'ph-duotone ph-users-three',
        path: '/organizations',
      },
    ],
  },
  {
    title: 'APPLICATION.MENU.NAV.retail',
    path: '',
    children: [
      {
        title: 'APPLICATION.MENU.NAV.wareStocks',
        icon: 'ph-duotone ph-carrot',
        path: '/stock-offers',
      },
      {
        title: 'APPLICATION.MENU.NAV.contracts',
        icon: 'ph-duotone ph-files',
        path: '/contracts',
      },
    ],
  },
  {
    title: 'APPLICATION.MENU.NAV.marketing',
    path: '',
    children: [
      {
        path: '/shop-offers',
        title: 'APPLICATION.MENU.NAV.shopOffers',
        icon: 'ph-duotone ph-shopping-cart',
        //TODO
        // componentKey
      },
      {
        title: 'APPLICATION.MENU.NAV.loyalty',
        icon: 'ph-duotone ph-crown',
        path: '',
        componentKey: 'Organization.ManageLoyalty',
        children: [
          {
            title: 'APPLICATION.MENU.NAV.loyaltyProducts',
            icon: 'ph-duotone ph-crown',
            path: '/loyalty/products',
          },
          {
            title: 'APPLICATION.MENU.NAV.loyaltyDocuments',
            icon: 'ph-duotone ph-crown',
            path: '/loyalty/documents',
          },
        ],
      },
    ],
  },
];

export class AppRoutesProvider implements IRouteProvider {
  private readonly authService: AuthService;

  get routes$(): Observable<RouteInfo[]> {
    return combineLatest([of(cloneDeepRoutes(ROUTES)), of(this.authService.getUserProfile()?.permissions)]).pipe(
      map(([routes, permissions]) => {
        const firstLevelFiltered = routes.filter(x => this.shouldDisplayRoute(x, permissions));

        for (const firstLevelFilteredElement of firstLevelFiltered) {
          if (!firstLevelFilteredElement.children) {
            continue;
          }

          if (firstLevelFilteredElement.children.length === 0) {
            continue;
          }

          firstLevelFilteredElement.children = firstLevelFilteredElement.children.filter(x => this.shouldDisplayRoute(x, permissions));
        }

        return firstLevelFiltered.filter(x => !x.children || x.children.length !== 0);
      }),
    );
  }

  constructor() {
    this.authService = inject(AuthService);
  }

  private shouldDisplayRoute(item: RouteInfo, userComponents: string[] | undefined) {
    if (!item.componentKey) {
      return true;
    }

    if (!userComponents) {
      return false;
    }

    return userComponents.some(claim => {
      return claim.startsWith(item.componentKey!);
    });
  }
}
