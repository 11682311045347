import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FullLayoutComponent } from './modules/commons/layouts/components/full-layout/full-layout.component';
import { AnonymousUser, LoggedUser } from './common/guards/logged-user.guard';

const routes: Routes = [
  {
    path: '',
    component: FullLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'stock-offers',
        pathMatch: 'full',
      },
      {
        path: 'stock-offers',
        loadChildren: () => import('./modules/stock-offers/stock-offer.module').then(m => m.StockOfferModule),
        canActivateChild: [LoggedUser],
      },
      {
        path: 'user-profile',
        loadChildren: () => import('./modules/user-profile/user-profile.module').then(m => m.UserProfileModule),
        canActivateChild: [LoggedUser],
      },
      {
        path: 'offers',
        loadChildren: () => import('./modules/offers/offers.module').then(m => m.OffersModule),
        canActivateChild: [LoggedUser],
      },
      {
        path: 'organizations',
        loadChildren: () => import('./modules/organizations/organizations.module').then(m => m.OrganizationsModule),
        canActivateChild: [LoggedUser],
      },
      {
        path: 'contracts',
        loadChildren: () => import('./modules/contracts/contracts.module').then(m => m.ContractsModule),
        canActivateChild: [LoggedUser],
      },
      {
        path: 'passport',
        loadChildren: () => import('./modules/passport/passport.module').then(m => m.PassportModule),
      },
      {
        path: 'loyalty',
        loadChildren: () => import('./modules/loyalty/loyalty.module').then(m => m.LoyaltyModule),
        canActivateChild: [LoggedUser],
        data: {
          componentKey: 'Organization.ManageLoyalty',
        },
      },
      {
        path: 'shop-offers',
        loadChildren: () => import('./modules/shop-offers/shop-offers.module').then(m => m.ShopOffersModule),
        canActivateChild: [LoggedUser],
        data: {
          //TODO
          // componentKey: 'Shop-Offers',
        },
      },
    ],
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
    canActivateChild: [AnonymousUser],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
