export interface RouteInfo {
  title: string;
  path?: string;
  icon?: string;
  children?: RouteInfo[];
  componentKey?: string;
}

export function cloneDeepRoutes(routes: RouteInfo[]): RouteInfo[] {
  return routes.map(route => cloneRoute(route));
}

function cloneRoute(route: RouteInfo): RouteInfo {
  return {
    ...route,
    children: route.children ? cloneDeepRoutes(route.children) : undefined,
  };
}
